import loadable from "@loadable/component";
import React from "react";

// ============ Auth ============
export const ResetPassword = React.lazy(() => import("pages/ResetPassword"));
export const PasswordEditForm = React.lazy(() =>
  import("pages/PasswordEditForm")
);
export const VerificationCodeForm = React.lazy(() =>
  import("pages/VerificationCodeForm")
);
// ============ Auth ============

export const AdminRouting = loadable(() =>
  import("modules/admin/AdminRouting")
);
export const UserRouting = loadable(() => import("modules/user/UserRouting"));
export const MessagesRouting = loadable(() =>
  import("modules/messages/MessagesRouting")
);
export const AcademicLevelRouting = loadable(() =>
  import("modules/academic_level/AcademicLevelRouting")
);
export const SpecializationRouting = loadable(() =>
  import("modules/specialization/SpecializationRouting")
);
export const HallRouting = loadable(() => import("modules/hall/HallRouting"));
export const ExhibitorRouting = loadable(() =>
  import("modules/exhibitor/ExhibitorRouting")
);
export const ContactRouting = loadable(() =>
  import("modules/contact-information/ContactRouting")
);
export const ExhibitionRouting = loadable(() =>
  import("modules/exhibition/ExhibitionRouting")
);
export const WebinarRouting = loadable(() =>
  import("modules/webinar/WebinarRouting")
);
export const NewsRouting = loadable(() => import("modules/news/NewsRouting"));
export const SystemInformationRouting = loadable(() =>
  import("modules/system_information/SystemInformationRouting")
);
export const NotificationRouting = loadable(() =>
  import("modules/notification/NotificationRouting")
);
export const SendEmailsRouting = loadable(() =>
  import("modules/send-emails/SendEmailsRouting")
);
export const RoleRouting = loadable(() =>
  import("modules/role-permission/RoleRouting")
);
export const AuditoriumRouting = loadable(() =>
  import("modules/auditorium/AuditoriumRouting")
);
// ============= Stands ======================
export const CoursesRouting = loadable(() =>
  import("modules/stand/course/CoursesRouting")
);
export const DesignRouting = loadable(() =>
  import("modules/stand/design/DesignRouting")
);
export const LinksRouting = loadable(() =>
  import("modules/stand/link/LinksRouting")
);
export const AdsHomeRouting = loadable(() =>
  import("modules/advertisement/home/AdsHomeRouting")
);
export const AdsLobbyRouting = loadable(() =>
  import("modules/advertisement/lobby/AdsLobbyRouting")
);
export const AdsHallRouting = loadable(() =>
  import("modules/advertisement/hall/AdsHallRouting")
);
export const ChatRouting = loadable(() => import("modules/chat/ChatRouting"));

// ============ DashboardRouting ============
export const DashboardRouting = React.lazy(() =>
  import("../modules/dashboard/DashboardRouting")
);
// ============ DashboardRouting ============
